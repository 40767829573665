// images.js
export const images = {
  lyftAppRedesign: require("../content/images/lyft-app-redesign.png"),
  adminDashboard: require("../content/images/admin-dashboard.png"),
  paywallRedesign: require("../content/images/paywall-redesign.png"),
  groupRides: require("../content/images/lbs-group-rides.png"),
  referralsRedesign: require("../content/images/referrals.png"),
  requestFlow: require("../content/images/request-flow-experiments.png"),
  figmaSignUpFlow: require("../content/images/figma-sign-up-flow-improvements.png"),
  loggedOutExperience: require("../content/images/logged-out-experience-redesign.png"),
  lockedStateRedesign: require("../content/images/locked-state-redesign.png"),
  proTierOnboarding: require("../content/images/pro-tier-onboarding.png"),
  lbsSignUpFlow: require("../content/images/lbs-sign-up-flow.png"),
  rideshareUpsells: require("../content/images/rideshare-upsells.png"),
  rideshareFiltering: require("../content/images/rideshare-filtering.png"),
  lbsActivation1: require("../content/images/lbs-activation-experiments-1.png"),
  lbsMemberships: require("../content/images/lbs-memberships-in-lyft-app.png"),
  lbsActivation2: require("../content/images/lbs-activation-experiments-2.png"),
  adminAppRedesign: require("../content/images/401k-admin-app-redesign.png"),
  planBuilder: require("../content/images/401k-plan-builder.png"),
  savingsFlow: require("../content/images/401k-savings-flow.png"),
  bettermentSignUpFlow: require("../content/images/401k-sign-up-flow.png"),
  planCompliance: require("../content/images/401k-plan-compliance.png"),
  rolloverFlow: require("../content/images/rollover-flow-improvements.png"),
  payrollIntegrations: require("../content/images/self-serve-payroll-integrations.png"),
};
