import React from "react";
import MobileMenu from "./MobileMenu";
import { docs } from "../data/docs";

export default function Header() {
  return (
    <header>
      <div className="header-left">
        <h1>Abby Jetmundsen</h1>
        <p className="location">Brooklyn, NY</p>
      </div>
      <MobileMenu />
      <nav className="desktop-nav">
        <a
          href="mailto:abbyjetmundsen@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </a>
        <a
          href="https://www.linkedin.com/in/abbyjetmundsen"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a href={docs.resume} target="_blank" rel="noopener noreferrer">
          Resume
        </a>
      </nav>
    </header>
  );
}
