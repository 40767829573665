import React, { useState } from "react";

const PasswordGate = ({ onAuthenticate }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const isValid = await onAuthenticate(password);
      if (!isValid) {
        throw new Error("Invalid password");
      }
    } catch (err) {
      setError("Sorry, that password is incorrect.");
      setPassword("");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (error) {
      setError("");
    }
  };

  return (
    <div className="password-gate">
      <div className="password-gate-container">
        <div className="password-gate-header">
          <div className="header-left">
            <h1>Abby Jetmundsen</h1>
            <p className="location">Product Designer</p>
          </div>
        </div>

        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="password-error-container">
            {error && <div className="password-error">{error}</div>}
          </div>

          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className={`password-input ${error ? "password-input-error" : ""}`}
            placeholder="Password"
            required
            autoFocus
            autoComplete="new-password"
            data-lpignore="true"
            data-form-type="other"
          />

          <button
            type="submit"
            disabled={isLoading}
            className="password-submit"
          >
            {isLoading ? "Verifying..." : "Log in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordGate;
