import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { X } from "lucide-react";

export default function Modal({ onClose, children }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    // Add enter animation class after a brief delay
    const timer = setTimeout(() => {
      const overlay = document.querySelector(".modal-overlay");
      const content = document.querySelector(".modal-content");
      if (overlay) overlay.classList.add("modal-overlay-enter");
      if (content) content.classList.add("modal-content-enter");
    }, 10);

    return () => {
      document.body.style.overflow = "unset";
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    const overlay = document.querySelector(".modal-overlay");
    const content = document.querySelector(".modal-content");

    // Add exit animation classes
    overlay.classList.add("modal-overlay-exit");
    content.classList.add("modal-content-exit");

    // Wait for animation to complete before closing
    setTimeout(onClose, 200);
  };

  return createPortal(
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={handleClose}>
          <X size={24} />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
}
