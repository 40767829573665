import React, { useState, useEffect } from "react";
import { docs } from "../data/docs";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        className="mobile-menu"
        onClick={toggleMenu}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        <div className={`menu-icon ${isOpen ? "menu-icon-open" : ""}`}>
          <span className="menu-line top"></span>
          <span className="menu-line middle"></span>
          <span className="menu-line bottom"></span>
        </div>
      </button>

      <div
        className={`mobile-menu-overlay ${isOpen ? "mobile-menu-open" : ""}`}
      >
        <div className="mobile-menu-content">
          <a
            href="mailto:abbyjetmundsen@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a>
          <a
            href="https://www.linkedin.com/in/abbyjetmundsen"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a href={docs.resume} target="_blank" rel="noopener noreferrer">
            Resume
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
