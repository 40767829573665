import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export async function verifyPassword(password) {
  try {
    const { data, error } = await supabase
      .from("access_codes")
      .select("organization")
      .eq("password_hash", password)
      .eq("is_active", true);

    if (error) throw error;

    if (data && data.length > 0) {
      return { organization: data[0].organization };
    }

    return null;
  } catch (error) {
    return null;
  }
}

export async function logAccess(organization, ipAddress, userAgent) {
  try {
    await supabase.from("access_logs").insert({
      organization,
      ip_address: ipAddress,
      user_agent: userAgent,
    });
  } catch (error) {
    // Silently fail if logging fails
  }
}
