import { images } from "./images";
import { videos } from "./videos";

export const projects = [
  {
    title: "Lyft App Redesign",
    company: "Lyft",
    year: "2023",
    image: images.lyftAppRedesign,
    alt: "Lyft App Redesign",
    description:
      "Worked with two other designers to develop a long-term vision and strategy for the Lyft app. Vision completed in 2023, app refresh shipped in 2024.",
  },
    {
    title: "Admin Dashboard",
    company: "Figma",
    year: "2023",
    image: images.adminDashboard,
    video: videos.adminDashboard,
    showVideoOnGrid: true,
    alt: "Admin Dashboard",
    description:
      "Designed a new surface where Figma plan admins can easily see all the pending requests on their team, and can take action on multiple requests at once. Generated $15M ARR in 2024.",
  },
  {
    title: "Paywall Redesign",
    company: "Figma",
    year: "2024",
    image: images.paywallRedesign,
    alt: "Paywall Redesign",
    description:
      "Led a design sprint to improve on Figma’s paywall and trial patterns. Tested with Dev Mode and later expanded to other products like Slides. Generated $2.3M ARR in 2024.",
  },
  {
    title: "Bike & Scooter Group Rides",
    company: "Lyft",
    year: "2022",
    image: images.groupRides,
    video: videos.groupRides,
    showVideoOnGrid: false,
    alt: "Bike & Scooter Group Rides",
    description:
      "Lyft owns and operates some of the largest bike and scooter sharing systems in the world. I led the design and development of a new group riding feature, which led to an additional +1.5M rides in its first year.",
  },
  {
    title: "Referrals Surface Redesign",
    company: "Lyft",
    year: "2022",
    image: images.referralsRedesign,
    video: videos.referralsRedesign,
    showVideoOnGrid: true,
    alt: "Referrals Surface Redesign",
    description:
      "Redesigned the referrals surface in the Lyft app. New design led to a 34% increase in referrals, and also made it possible for teams across Lyft to build and experiment with referral offers for the first time.",
  },
  {
    title: "Request Flow Experiments",
    company: "Figma",
    year: "2024",
    image: images.requestFlow,
    alt: "Request Flow Experiments",
    description:
      "Designed a new request nudging pattern that appears in Figma's editor surfaces. The new nudging system led to a 5% increase in approval rates.",
  },
  {
    title: "Sign Up Flow Improvements",
    company: "Figma",
    year: "2024",
    image: images.figmaSignUpFlow,
    alt: "Sign Up Flow Improvements",
    description:
      "Simplified Figma’s sign up flow. Drove a 2% increase in global sign up rates and a 35x increase in SSO sign up rates.",
  },
  {
    title: "Logged Out Experience Redesign",
    company: "Figma",
    year: "2024",
    image: images.loggedOutExperience,
    alt: "Logged Out Experience Redesign",
    description:
      "Redesigned Figma’s logged out product surfaces to capture new users more effectively. Increased the mobile web sign up rate by 177%, and drove a 1% increase in global signups.",
  },
  {
    title: "Locked State Redesign",
    company: "Figma",
    year: "2023",
    image: images.lockedStateRedesign,
    alt: "Locked State Redesign",
    description:
      "Redesigned Figma’s locked state to recapture churned users more effectively.",
  },
  {
    title: "Pro Tier Onboarding",
    company: "Figma",
    year: "2023",
    image: images.proTierOnboarding,
    video: videos.proTierOnboarding,
    showVideoOnGrid: false,
    alt: "Pro Tier Onboarding",
    description:
      "I designed a new onboarding experience for teams who upgrade to Figma Pro. The new patterns led to a 2% increase in long-term retention.",
  },
  {
    title: "Bike & Scooter Sign Up Flow",
    company: "Lyft",
    year: "2023",
    image: images.lbsSignUpFlow,
    alt: "Bike & Scooter Sign Up Flow",
    description:
      "I simplified and improved on the sign up and onboarding flows in Lyft’s partner-branded bike and scooter apps.",
  },
  {
    title: "Rideshare Upsell Experiments",
    company: "Lyft",
    year: "2022",
    image: images.rideshareUpsells,
    video: videos.rideshareUpsells,
    showVideoOnGrid: false,
    alt: "Rideshare Upsell Experiments",
    description:
      "Designed a series of upsell experiments for Lyft’s rideshare booking flow. Generated $12M in profit in its first year. Learnings were used to inform the larger Lyft App redesign.",
  },
  {
    title: "Rideshare Filtering Experiments",
    company: "Lyft",
    year: "2022",
    image: images.rideshareFiltering,
    video: videos.rideshareFiltering,
    showVideoOnGrid: true,
    alt: "Rideshare Filtering Experiments",
    description:
      "Experimented with alternate methods of filtering in Lyft’s rideshare booking flow. Learnings were used to inform the larger Lyft App redesign.",
  },
  {
    title: "Bike & Scooter Activation Experiments",
    company: "Lyft",
    year: "2023",
    image: images.lbsActivation1,
    alt: "Bike & Scooter Activation Experiments",
    description:
      "Designed a series of lightweight experiments to boost activation rates for bike and scooter users who signed up on web.",
  },
  {
    title: "Bike & Scooter Memberships in the Lyft App",
    company: "Lyft",
    year: "2022",
    image: images.lbsMemberships,
    alt: "Bike & Scooter Memberships in the Lyft App",
    description:
      "Iterated on the design of Lyft’s membership surface to allow for bike and scooter memberships to be sold in the Lyft app.",
  },
  {
    title: "Bike & Scooter Activation Experiments",
    company: "Lyft",
    year: "2021",
    image: images.lbsActivation2,
    alt: "Bike & Scooter Activation Experiments",
    description:
      "Designed a new experience to activate users who scan bike and scooter QR codes without having the app installed.",
  },
  {
    title: "401(k) App Redesign",
    company: "Betterment",
    year: "2020",
    image: images.adminAppRedesign,
    alt: "401(k) App Redesign",
    description:
      "Led a major redesign of Betterment’s 401(k) admin app. The redesign led to an 18% reduction in customer service inquiries, despite an almost 5x increase in customers.",
  },
  {
    title: "401(k) Plan Builder",
    company: "Betterment",
    year: "2019",
    image: images.planBuilder,
    alt: "401(k) Plan Builder",
    description:
      "Led the design of Betterment’s first self-serve sales flow for 401(k) plans. Generated a 450% increase in sales.",
  },
  {
    title: "401(k) Savings Flow",
    company: "Betterment",
    year: "2021",
    image: images.savingsFlow,
    alt: "401(k) Savings Flow",
    description:
      "Brought 401(k) plans into the Betterment mobile app for the first time, and designed flows that allowed end users to manage their 401(k) in the app.",
  },
  {
    title: "401(k) Sign Up Flow",
    company: "Betterment",
    year: "2021",
    image: images.bettermentSignUpFlow,
    alt: "401(k) Sign Up Flow",
    description:
      "Simplified and improved on the sign up flow for 401(k)-first users.",
  },
  {
    title: "401(k) Plan Compliance",
    company: "Betterment",
    year: "2021",
    image: images.planCompliance,
    alt: "401(k) Plan Compliance",
    description:
      "Designed new tooling to help plan admins navigate compliance season. Led to a 23% decrease in customer service inquiries, despite a 42% increase in customers.",
  },
  {
    title: "Rollover Flow Improvements",
    company: "Betterment",
    year: "2020",
    image: images.rolloverFlow,
    alt: "Rollover Flow Improvements",
    description:
      "Built a new self-serve flow that allowed customers to roll over old 401(k) accounts on their own, without needing to contact customer support.",
  },
  // {
  //   title: "Self-Serve Payroll Integrations",
  //   company: "Betterment",
  //   year: "2021",
  //   image: images.payrollIntegrations,
  //   alt: "Self-Serve Payroll Integrations",
  //   description:
  //     "Built new self-serve flows that allowed customers to set up payroll integrations on their own, without needing to contact customer support.",
  // },
];
