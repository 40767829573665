import React from "react";

export default function Intro() {
  return (
    <section className="intro">
      <h2>
        I'm a product designer who's worked at companies like Figma, Lyft, and
        Betterment.
        <br />
        <br />
        My work is defined by a strong sense of craft and a focus on business
        impact.
      </h2>
    </section>
  );
}
