import React, { useState, useEffect } from "react";

export default function FontLoader({ children }) {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  useEffect(() => {
    Promise.all([
      document.fonts.load('300 1em "SF Pro Display"'),
      document.fonts.load('400 1em "SF Pro Display"'),
      document.fonts.load('500 1em "SF Pro Display"'),
      document.fonts.load('600 1em "SF Pro Display"'),
      document.fonts.load('300 1em "Loretta"'),
    ]).then(() => {
      setFontsLoaded(true);
      // Delay showing projects slightly
      setTimeout(() => setShowProjects(true), 100);
    });
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return React.cloneElement(children, { showProjects });
}
