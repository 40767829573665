import "./styles.css";
import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Intro from "./components/Intro";
import ProjectsGrid from "./components/ProjectsGrid";
import Footer from "./components/Footer";
import FontLoader from "./components/FontLoader";
import PasswordGate from "./components/PasswordGate";
import { verifyPassword, logAccess } from "./lib/supabase";
import Cookies from "js-cookie";

// Cookie name constant
const AUTH_COOKIE_NAME = "portfolio_auth";
// Cookie expiry in days (adjust as needed)
const COOKIE_EXPIRY_DAYS = 7;

export default function App() {
  return (
    <FontLoader>
      <AppContent />
    </FontLoader>
  );
}

function AppContent({ showProjects }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const initialContentStyle = {
    opacity: contentVisible ? 1 : 0,
    transition: "opacity 0.8s ease-out",
  };

  const projectsStyle = {
    opacity: showProjects && contentVisible ? 1 : 0,
    transform: showProjects ? "translateY(0)" : "translateY(20px)",
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  };

  // Check for existing authentication cookie on mount
  useEffect(() => {
    const checkAuth = async () => {
      const authCookie = Cookies.get(AUTH_COOKIE_NAME);
      if (authCookie) {
        try {
          const cookieData = JSON.parse(authCookie);
          const result = await verifyPassword(cookieData.password);

          if (result?.organization) {
            setIsAuthenticated(true);
            setOrganization(result.organization);
          } else {
            // If verification fails, remove the invalid cookie
            Cookies.remove(AUTH_COOKIE_NAME);
          }
        } catch (error) {
          // If there's an error parsing the cookie, remove it
          Cookies.remove(AUTH_COOKIE_NAME);
        }
      }
      setIsCheckingAuth(false);
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const timer = setTimeout(() => {
        setContentVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  const handleAuthentication = async (password) => {
    const result = await verifyPassword(password);

    if (result?.organization) {
      // Store authentication in a cookie
      Cookies.set(
        AUTH_COOKIE_NAME,
        JSON.stringify({ password, organization: result.organization }),
        { expires: COOKIE_EXPIRY_DAYS }
      );

      setIsAuthenticated(true);
      setOrganization(result.organization);
      await logAccess(result.organization, "IP_ADDRESS", navigator.userAgent);
      return true;
    }
    return false;
  };

  // Show loading state while checking authentication
  if (isCheckingAuth) {
    return null;
  }

  if (!isAuthenticated) {
    return <PasswordGate onAuthenticate={handleAuthentication} />;
  }

  return (
    <div className="container">
      <div className="initial-content" style={initialContentStyle}>
        <Header />
        <Intro />
      </div>
      <div style={projectsStyle}>
        <ProjectsGrid />
      </div>
      <Footer />
    </div>
  );
}
