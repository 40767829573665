import React from "react";
import Project from "./Project";
import { projects } from "../data/projects";

export default function ProjectsGrid() {
  return (
    <section className="projects-grid">
      {projects.map((project, index) => (
        <Project key={index} {...project} />
      ))}
    </section>
  );
}
