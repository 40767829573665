import React from "react";
import { docs } from "../data/docs";

export default function Footer() {
  return (
    <footer>
      <div className="footer-border">
        <nav className="footer-nav">
          <a
            href="mailto:abbyjetmundsen@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a>
          <a
            href="https://www.linkedin.com/in/abbyjetmundsen"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a href={docs.resume} target="_blank" rel="noopener noreferrer">
            Resume
          </a>
        </nav>
      </div>
    </footer>
  );
}
