import React, { useState, useEffect, useRef } from "react";
import Modal from "./Modal";

export default function Project({
  title,
  company,
  year,
  image,
  video,
  alt,
  description,
  showVideoOnGrid
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);

  // Pause video when modal opens
  useEffect(() => {
    if (isModalOpen && videoRef.current) {
      videoRef.current.pause();
    } else if (!isModalOpen && videoRef.current) {
      videoRef.current.play();
    }
  }, [isModalOpen]);

  // Render video or image on grid based on showVideoOnGrid property
  const renderGridItem = () => {
    if (video && showVideoOnGrid) {
      return (
        <video
          ref={videoRef}
          className="project-image"
          autoPlay
          loop
          muted
          playsInline
          poster={image}
        >
          <source src={video} type="video/mp4" />
          <img src={image} alt={alt} />
        </video>
      );
    }
    return (
      <img
        src={image}
        alt={alt}
        className="project-image"
      />
    );
  };

  // In modal, always render video if available
  const renderModalContent = () => {
    if (video) {
      return (
        <video
          className="modal-image"
          autoPlay
          loop
          muted
          playsInline
          poster={image}
        >
          <source src={video} type="video/mp4" />
          <img src={image} alt={alt} />
        </video>
      );
    }
    return (
      <img
        src={image}
        alt={alt}
        className="modal-image"
      />
    );
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="project-wrapper">
      <div className="project" onClick={handleOpenModal}>
        {renderGridItem()}
      </div>
      <div className="project-info">
        <h3>{title}</h3>
        <p>
          {company}, {year}
        </p>
      </div>

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          {renderModalContent()}
          <div className="modal-info">
            <h2>{title}</h2>
            {description && <p className="modal-description">{description}</p>}
          </div>
        </Modal>
      )}
    </div>
  );
}
